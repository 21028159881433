export const PASSPORT_PAGE_STEPS = {
  CODE: 'CODE',
  DOCUMENT_TYPE_CHOICE: 'DOCUMENT_TYPE_CHOICE',
  FORM_FILL_DATA: 'FORM_FILL_DATA',
  FORM_UPLOAD_FILES: 'FORM_UPLOAD_FILES',
  FORM_OFFER_CDEK_ID: 'FORM_OFFER_CDEK_ID',
  FORM_SEND_FILES: 'FORM_SEND_FILES',
  FORM_SEND_FORM: 'FORM_SEND_FORM',
};

export const DOCUMENTS_TYPES = {
  PASSPORT: 'PASSPORT',
  ID_CARD: 'ID_CARD',
  OTHER: 'OTHER',
};

export const CITIZENSHIP_ID_MAP = {
  RU: 'RU',
  KZ: 'KZ',
  KG: 'KG',
  BY: 'BY',
  UZ: 'UZ',
  AM: 'AM',
  GE: 'GE',
  AZ: 'AZ',
  IL: 'IL',
  OTHER: 'OTHER',
};

export const PASSPORT_LOADING_STATUSES = {
  LOADING: 'LOADING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  INCORRECT: 'INCORRECT',
  SUCCESS_CDEK_ID: 'SUCCESS_CDEK_ID',
  ERROR_CDEK_ID: 'ERROR_CDEK_ID',
};

export const ERRORS_MAP = {
  err_identity_document_scan_required: 'Скан паспорта обязателен',
  err_internal: 'Внутренняя ошибка. Скоро починим!',
  err_idx_unknown: 'Что-то сломалось. Уже выясняем',
  err_idx_parse: 'Не удалось распознать скан. Введите данные вручную',
  err_idx_validate_name_date: 'Некорректные ФИО и дата рождения. Пожалуйста, перепроверьте данные.',
  err_idx_validate_name_date_number:
    'Некорректные ФИО, дата рождения и номер паспорта. Пожалуйста, перепроверьте данные',
  err_idx_validate_non_correct:
    'Не можем проверить паспортные данные. Пожалуйста, перепроверьте данные.',
  err_idx_validate_issue_date_verified:
    'Дата выдачи паспорта не подтверждена. Пожалуйста, перепроверьте данные.',
  err_save_identity_document: 'Не удалось сохранить паспортные данные.',
  err_can_not_get_file: 'Ошибка чтения файла.',
  err_fns_connect: 'Ошибка запроса ИНН',
  err_fns_validate_tin: 'Ошибка проверки паспортных данных.',
  err_fns_validate_tin_mismatch: 'Указанный ИНН не соотвествует паспортным данным.',
  err_invalid_phone: 'Невозможно обработать уведомление. Отсутствует номер телефона.',
  err_invalid_order_number: 'Невозможно обработать уведомление. Отсутствует номер заказа.',
  err_validation_failed: 'Ошибка валидации документа.',
  err_name_is_mandatory: 'Имя обязательно к заполнению.',
  err_invalid_name: 'Ошибка в имени.',
  err_surname_is_mandatory: 'Фамилия обязательна к заполнению.',
  err_invalid_surname: 'Ошибка в фамилии.',
  err_invalid_patronymic: 'Ошибка в отчестве.',
  err_invalid_nationality: 'Ошибка в национальности.',
  err_passport_number_is_mandatory: 'Номер паспорта обязателен к заполнению.',
  err_invalid_passport_number: 'Ошибка в номере паспорта.',
  err_invalid_place_of_birth: 'Ошибка в месте рождения.',
  err_date_of_birth_is_mandatory: 'Дата рождения обязательна к заполнению.',
  err_invalid_date_of_birth: 'Ошибка в дате рождения.',
  err_date_of_issue_is_mandatory: 'Дата выдачи обязательна к заполнению.',
  err_invalid_date_of_issue: 'Ошибка в дате выдачи документа.',
  err_authority_is_mandatory: 'Необходимо укзать место выдачи документа.',
  err_invalid_authority: 'Ошибка в месте выдачи документа.',
  err_invalid_date_of_expiry: 'Ошибка срока действия документа.',
  err_iin_is_mandatory: 'Индивидуальный идентификационный номер обязателен к заполнению.',
  err_invalid_iin: 'Ошибка в индивидуальном идентификационном номере.',
  err_card_number_is_mandatory: 'Номер карты обязателен к заполнению.',
  err_invalid_card_number: 'Ошибка в номере карты.',
  err_pin_is_mandatory: 'Персональный идентификационный номер обязателен к заполнению.',
  err_invalid_pin: 'Ошибка в персональном идентификационном номере.',
  err_document_number_is_mandatory: 'Номер документа обазятелен к заполнению.',
  err_invalid_document_number: 'Ошибка в номере документа.',
  err_invalid_document: 'Ошибка в документе.',
  err_collector_not_found: 'Процесс сбора документов не найден.',
  err_collector_is_cancelled: 'Процесс сбора документов отменен.',
  err_collector_is_stopped: 'Процесс сбора документов остановлен.',
  err_collector_is_completed: 'Процесс сбора документов завершен.',
  err_person_not_found: 'Персона не найдена.',
  err_order_client_not_found: 'Клиент заказа не найден.',
  err_invalid_tin: 'Неверный ИНН. Пожалуйста, перепроверьте паспортные данные и повторите.',
  file_error:
    'Скан удостоверения личности является обязательным при не совпадении страны получения с гражданством.',
  err_passport_serial_is_mandatory: 'Серия паспорта обязательна к заполнению.',
  err_invalid_passport_serial: 'Ошибка в серии паспорта.',
  err_cant_overwrite_document:
    'Ваши данные уже были переданы ранее. Повторная отправка возможна через 24 часа.',
  err_date_of_expiry_is_mandatory: 'Необходимо указать дату окончания срока действия документа.',
  err_card_serial_is_mandatory: 'Серия карты обязательна к заполнению.',
  err_invalid_card_serial: 'Ошибка в серии карты.',
  err_citizenship_is_mandatory: 'Необходимо указать страну выдачи документа.',
  err_invalid_citizenship: 'Ошибка в стране гражданства документа.',
  err_tin_is_mandatory: 'Идентификационный номер налогоплательщика обязателен к заполнению.',
  err_identification_number_is_mandatory: 'Идентификационный номер обязателен к заполнению.',
  err_invalid_identification_number: 'Ошибка в идентификационном номере.',
  err_invalid_country_iso3: 'Ошибка в ISO3166.1-alpha3 коде страны.',
  err_contract_not_found: 'Договор не найден.',
};

export const ALLOWED_HTTP_CODES_4XX = [401, 403];
export const ALLOWED_HTTP_CODES_5XX = [500];
export const ALLOWED_ERROR_CODES = [
  'err_collector_is_cancelled',
  'err_collector_is_stopped',
  'err_collector_is_completed',
  'err_internal',
];

export const ERROR_COLLECTOR_IS_COMPLETED = 'err_collector_is_completed';
